import store from '@/store';
import {axios_instance as axios} from '@/axios-config';

store.subscribe((mutation) => {
  switch (mutation.type) {
    case 'auth/SET_TOKEN':
      if (mutation.payload) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
        localStorage.setItem('token', mutation.payload);
      } else {
        axios.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('token');
      }
      break;
    case 'SET_SELECTED_DEPARTMENT':
      store.dispatch('plans/getPlans');
      break;
  }
});
