<template>
  <div>
    <div class="container-fluid">
      <div class="row justify-content-center navigation-row">
        <div class="col-12 pb-0">
          <NavigationView></NavigationView>
        </div>
      </div>
      <div class="row justify-content-center main-content-row">
        <div class="col-12 bg-box"></div>
        <template v-if="$route.name">
          <div class="col-12 col-xl-10">
            <h4 class="text-white pt-5">{{ $route.meta.fullName }}
              <template v-if="this.dietData && $route.path.includes('menus')">
                / {{ dietData.name }}
                <span v-if="dietData.shortcut && dietData.color" class="diet-color-pill"
                  :style='{"background-color" : dietData.color, "color": getContrastYIQ(dietData.color),}'>
                  {{dietData.shortcut}}
                </span>
                <span v-else class="diet-color-pill" :style='{"border" : "1px solid"}'>
                  {{dietData.shortcut}}
                </span>
              </template>
            </h4>
          </div>
        </template>
        <Content></Content>
        <!-- <router-view/> -->
      </div>
    </div>
  </div>
</template>

<script>
import NavigationView from '@/components/NavigationView.vue'
import Content from './ContentView.vue'
import { EventBus } from '@/main'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    NavigationView,
    Content,
  },
  data() {
    return {
      dietData: null,
      customIntervalId: null,
    }
  },
  created() {
    EventBus.$on('dietChildData', (diet) => {
      this.dietData = diet;
    });

    this.customIntervalId = setInterval(async () => {
      if (!this.authenticated) {
        clearInterval(this.customIntervalId);
        return;
      }

      const isSuccess = await this.getPlans('loop');

      if (!isSuccess) {
        clearInterval(this.customIntervalId);
      }
    }, 60000)
  },
  methods: {
    ...mapActions('plans', ['getPlans']),
    getContrastYIQ(hexcolor) {
      const r = parseInt(hexcolor.substr(1, 3), 16);
      const g = parseInt(hexcolor.substr(3, 4), 16);
      const b = parseInt(hexcolor.substr(5, 3), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? 'black' : 'white'
    },
  },
  computed: mapGetters('auth', ['authenticated']),
  watch: {
    $route() {
      this.dietData = null;
    },
  },
}
</script>

<style lang="scss">
@import '../assets/css/variables';
.navigation-row {
  background-color: #403a8f;
}
.main-content-row {
  position: relative;
  padding-bottom: 30px;
}
@media only screen and (min-width: 600px) {
  .v-snack__wrapper {
    width: auto;
    max-width: 568px;
    min-width: 488px;
    margin: 0 auto;
    border-radius: 2px;
  }
}
.v-snack--multi-line .v-snack__content {
  height: auto;
}

.diet-color-pill {
  font-size:1rem;
  margin-left: 10px;
  display: inline-block;
  align-items: center;
  border-radius:3px;
  padding: 1px 15px;
  vertical-align: middle;
}
</style>
