
import {axios_instance as axios} from '@/axios-config.js';
import router from '../router';

export default {
    namespaced: true,

    state: {
        elearningList: null,
        totalItems: null,
        elearningItem: null,
        elearningContent: null
    },

    mutations: {
        SET_ELEARNING_LIST(state, payload) {
            state.elearningList = payload.data['hydra:member']
        },
        SET_TOTAL_ITEMS(state, payload) {
            state.totalItems = payload.data['hydra:totalItems']
        },
        SET_ITEM(state, payload) {
            state.elearningItem = payload.data;
        },
        SET_CONTENT(state) {
            state.elearningContent = state.elearningItem.content;
        }
    },

    actions: {
        getElearning({ commit }) {
            return new Promise((resolve, reject)=>{
                axios
                .get('app/e-learning?context=elearningContent')
                .then(response => {
                    commit('SET_ELEARNING_LIST', response)
                    commit('SET_TOTAL_ITEMS', response)
                    resolve();
                })
                .catch(error => {
                reject(error)
                });
            })
        },
        getElearningItem({ commit }) {
            return new Promise((resolve, reject)=>{
                axios
                .get('app/e-learning/' + router.history.current.params.id + '?context=elearningContent')
                .then(response => {
                    commit('SET_ITEM', response)
                    commit('SET_CONTENT')
                    resolve();
                })
                .catch(error => {
                    reject(error)
                });
            })
        }
    }
}
