<template>

<nav class="navbar navbar-expand-lg navbar-dark py-0">
  <router-link to="/" tag="a" class="navbar-brand py-0">
    <img class='logo-nav-block__logo' src="../assets/images/logo-imfood.jpg" alt="imFood logo">
  </router-link>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon" />
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <router-link
              :to="{ name: 'calendar' }"
              v-slot="{ href, navigate, isExactActive }"
              v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_NOURISHED_PLAN_LIST_SHOW')"
      >
        <li class="nav-item" :class="[isExactActive && 'active']">
          <a :href="href" class="nav-link" @click="navigate">
            <i class="icon-kalendarz" />
            Kalendarz
          </a>
        </li>
      </router-link>

      <router-link
              :to="{ name: 'dietsGrid' }"
              v-slot="{ href, navigate, isExactActive }"
              v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_DIET_MENU_LIST_SHOW')"
      >
        <li class="nav-item" :class="[isExactActive && 'active']">
          <a :href="href" class="nav-link" @click="navigate">
            <i class="icon-diety" />
            Jadłospisy
          </a>
        </li>
      </router-link>

      <router-link
              :to="{ name: 'plans' }"
              v-slot="{ href, navigate, isExactActive }"
              v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_NOURISHED_PLAN_LIST_SHOW')"
      >
        <li class="nav-item" :class="[isExactActive && 'active']">
          <a :href="href" class="nav-link" @click="navigate">
            <i class="icon-plany-zywionych" />
            Plany żywionych
          </a>
        </li>
      </router-link>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" :class="[isParentHelpActive ? 'active' : '']" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fe fe-life-buoy" />
          Pomoc
          <i class="ml-1 fe fe-chevron-down" />
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
<!--          <router-link to="/elearning" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_ELEARNING')">-->
<!--            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">-->
<!--              <i class="fe fe-play mr-2" />E-learning-->
<!--            </a>-->
<!--          </router-link>-->
          <router-link
                  :to="{ name: 'knowledgeBaseList' }"
                  v-slot="{ href, navigate, isExactActive }"
                  v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW')"
          >
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-book-open mr-2" />Baza wiedzy
            </a>
          </router-link>
          <router-link
                  :to="{ name: 'ticketsGrid' }"
                  v-slot="{ href, navigate, isExactActive }"
                  v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_TICKET_LIST_SHOW')"
          >
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-bookmark mr-2" />Zgłoszenia
            </a>
          </router-link>
        </div>
      </li>

      <li class="nav-item dropdown" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_SHOW_LIST')">
        <a class="nav-link dropdown-toggle" :class="[isParentReportsActive ? 'active' : '']" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fe fe-database" />
          Raporty
          <i class="ml-1 fe fe-chevron-down" />
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown2">
          <router-link
                  :to="{ name: 'reportNourished' }"
                  v-slot="{ href, navigate, isExactActive }"
                  v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NOURISHED_PLAN')"
          >
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-database mr-2" />
              Raport ilości żywionych
            </a>
          </router-link>
          <router-link
                  :to="{ name: 'reportIncome' }"
                  v-slot="{ href, navigate, isExactActive }"
                  v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_INCOME_VALUE')"
          >
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-database mr-2" />
              Miesięczna wartość usługi
            </a>
          </router-link>
          <router-link
                  :to="{ name: 'reportNutritional' }"
                  v-slot="{ href, navigate, isExactActive }"
                  v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NUTRITIONAL_VALUES')"
          >
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-database mr-2" />
              Raport wartości odżywczych
            </a>
          </router-link>
        </div>
      </li>
    </ul>

    <div class="ml-auto">
      <ul class="navbar-nav">
        <SelectDepartment class="order-4 order-lg-1" />

        <li class="nav-item dropdown order-2">
          <a aria-expanded="false" @click="setIncomingInfo" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdownBell" role="button" style="position: relative">
            <i class="fe fe-bell mr-1 mr-lg-0" />
            <span class="d-lg-none">Powiadomienia</span>
            <span class="d-block bg-danger" v-if="incoming_info === true" style="position: absolute; z-index:1; height:10px; width:10px; top:30%; right:10%; border-radius: 10px" />
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBell">
            <div class="dropdown-header text-white">
              <template v-if="incoming_info === false">Brak zmian w planach żywionych</template>
              <template v-else>Uwaga! Plany żywionych zostały zaktualizowane.</template>
            </div>
          </div><!-- end dropdown-menu -->
        </li><!-- end nav-item dropdown -->

        <li class="nav-item dropdown order-3">
          <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdownProfile" role="button">
            <i class="icon-user mr-1 mr-lg-0" />
            <span class="d-lg-none">Profil</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
            <h6 class="dropdown-header text-white">
              <strong>{{ $store.getters['auth/user'].fullName }}</strong><br/>{{ $store.getters['auth/user'].userName }}
            </h6>
            <a class="dropdown-item" @click="logOut" href="">
              <i class="icon-wyloguj pr-2" />Wyloguj
            </a>
          </div><!-- end dropdown-menu -->
        </li><!-- end nav-item dropdown -->
      </ul><!-- end navbar-nav -->

    </div>

  </div>
</nav>
</template>

<script>
import SelectDepartment from "./SelectDepartmentView.vue";
import { mapState } from "vuex";

export default {
  name: "navigationView",
  components: {
    SelectDepartment,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("plans", ["incoming_info"]),
    isParentHelpActive() {
      if (
        this.$route.path.includes("elearning") ||
        this.$route.path.includes("knowledge_base") ||
        this.$route.path.includes("tickets")
        ) {
        return true
      }
      return false
    },
    isParentReportsActive() {
      if (
        this.$route.path.includes("report_nourished") ||
        this.$route.path.includes("report_income") ||
        this.$route.path.includes("report_nutritional")
        ) {
        return true
      }
      return false
    },

  },
  methods: {
    logOut() {
      localStorage.removeItem("token");
    },
    setIncomingInfo() {
      setTimeout(() => {
        this.$store.commit("plans/SET_INCOMING_INFO", false);
      }, 10000);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
