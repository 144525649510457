import Vue from 'vue';
import App from './App.vue';
import MaintenancePage from './views/MaintenancePage.vue';
import VersionPage from './views/VersionPage.vue';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vuetify/dist/vuetify.min.css';
import VueProgressBar from 'vue-progressbar';

import router from './router';
import store from './store';

import wysiwyg from 'vue-wysiwyg';
import Vuetify from 'vuetify';
import './assets/css/app.scss';

require('@/store/subscriber');

Vue.config.productionTip = false;

export const EventBus = new Vue();
export const MaintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE;

if (MaintenanceMode === '0') {
  const vuetifyOptions = {
    dark: true,
  };
  Vue.use(Vuetify);
  Vue.use(wysiwyg, {});
  Vue.use(VueProgressBar, {
    color: '#00baff',
    failedColor: 'red',
    height: '2px',
  });

  new Vue({
    router,
    store,
    vuetify: new Vuetify(vuetifyOptions),
    render: h => h(App)
  }).$mount('#app');
} else {
  new Vue({
    render: h => h(MaintenancePage)
  }).$mount('#app');
}

new Vue({
  render: h => h(VersionPage),
}).$mount('#version');

window.$ = $;
