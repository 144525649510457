import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/MainPage.vue'
import Login from '../views/LoginPage.vue';
import CalendarPage from '../views/CalendarPage.vue';
import MiniCalendar from '../views/MiniCalendarView.vue';
import DietPage from '../views/DietPage.vue';
import Container from '../views/PasswordRecovery/ContainerView.vue';
import Step1 from '../views/PasswordRecovery/Step1Page.vue';
import Step2 from '../views/PasswordRecovery/Step2Page.vue';
import SwitchUser from '../views/SwitchUser.vue';
import NourishedPlanManagement from '@/views/Nourishment/NourishedPlanManagement.vue'
import PageNotFound from '../views/PageNotFound.vue';
import store from '../store';

Vue.use(VueRouter);

// const ELearning = () => import('../views/ELearning/ELearningPage.vue');
// const ELearningShow = () => import('../views/ELearning/ELearningShowPage.vue');
const DietGrid = () => import('../views/Diets/DietGrid.vue');
const KnowledgeBase = () => import('../views/KnowledgeBase/KnowledgeBase.vue');
const KnowledgeBaseShow = () => import('../views/KnowledgeBase/KnowledgeBaseShow.vue');
const TicketGrid = () => import('../views/Tickets/TicketGrid.vue');
const TicketAdd = () => import('../views/Tickets/TicketAdd.vue');
const TicketShow = () => import('../views/Tickets/TicketShow.vue');
const ReportNourished = () => import('../views/Reports/ReportNourished.vue');
const ReportIncome = () => import('../views/Reports/ReportIncome.vue');
const ReportNutritional = () => import('../views/Reports/ReportNutritional.vue');


export const routes = [
  {
    path: '/',
    component: Main,
    name: 'main',
    children: [
      {
        path: '',
        name: 'calendar',
        component: CalendarPage,
        meta: { requiresAuth: true, fullName: 'Kalendarz', role: "ROLE_AS_NOURISHED_PLAN_LIST_SHOW"}
      },
      {
        path: 'diets',
        name: 'dietsGrid',
        component: DietGrid,
        meta: { requiresAuth: true, fullName: 'Jadłospisy', role: "ROLE_AS_DIET_MENU_LIST_SHOW"}
      },
      {
        path: 'menus/show/:id',
        name: 'menuShow',
        component: DietPage,
        meta: { requiresAuth: true, fullName: 'Jadłospis', role: "ROLE_AS_DIET_MENU_LIST_SHOW"}
      },
      {
        path: 'plans',
        name: 'plans',
        component: MiniCalendar,
        meta: { requiresAuth: true, fullName: 'Plany żywionych', role: "ROLE_AS_NOURISHED_PLAN_LIST_SHOW"},
        children: [
          {
            path: 'show/:id',
            name: 'Plany żywionych / Pokaż',
            component: NourishedPlanManagement,
            props: { default: true },
            meta: { requiresAuth: true, fullName: 'Plany żywionych', role: "ROLE_AS_NOURISHED_PLAN_ADD_EDIT"},
          },
        ],
      },
      {
        path: 'report/nourished',
        name: 'reportNourished',
        component: ReportNourished,
        meta: { requiresAuth: true, fullName: 'Raport : Ilość żywionych', role: "ROLE_AS_REPORT_NOURISHED_PLAN"}
      },
      {
        path: 'report/income',
        name: 'reportIncome',
        component: ReportIncome,
        meta: { requiresAuth: true, fullName: 'Raport : Miesięczna wartość usługi', role: "ROLE_AS_REPORT_INCOME_VALUE"}
      },
      {
        path: 'report/nutritional',
        name: 'reportNutritional',
        component: ReportNutritional,
        meta: { requiresAuth: true, fullName: 'Raport : Wartości odżywcze', role: "ROLE_AS_REPORT_NUTRITIONAL_VALUES"}
      },
      // {
      //   path: 'elearning',
      //   name: 'Pomoc / E-learning',
      //   component: ELearning,
      //   props: { conf: conf },
      //   meta: { requiresAuth: true, fullName: 'E-learning', role: "ROLE_AS_ELEARNING"}
      // },
      // {
      //   path: 'elearning/show/:id',
      //   name: 'Pomoc / E-learning / Pokaż',
      //   component: ELearningShow,
      //   meta: { requiresAuth: true, fullName: 'E-learning', role: "ROLE_AS_ELEARNING"}
      // },
      {
        path: 'knowledge-base',
        name: 'knowledgeBaseList',
        component: KnowledgeBase,
        meta: { requiresAuth: true, fullName: 'Baza wiedzy', role: "ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW"}
      },
      {
        path: 'knowledge-base/show/:knowledgeArticleId',
        name: 'knowledgeBaseShow',
        component: KnowledgeBaseShow,
        meta: { requiresAuth: true, fullName: 'Baza wiedzy', role: "ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW"}
      },
      {
        path: 'tickets',
        name: 'ticketsGrid',
        component: TicketGrid,
        meta: { requiresAuth: true, fullName: 'Zgłoszenia', role: "ROLE_AS_TICKET_LIST_SHOW"}
      },
      {
        path: 'tickets/add',
        name: 'ticketAdd',
        component: TicketAdd,
        meta: { requiresAuth: true, fullName: 'Dodawanie zgłoszenia', role: "ROLE_AS_TICKET_ADD_EDIT"}
      },
      {
        path: 'tickets/show/:id',
        name: 'ticketShow',
        component: TicketShow,
        meta: { requiresAuth: true, fullName: 'Zgłoszenie', role: "ROLE_AS_TICKET_ADD_EDIT"}
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false, fullName: 'Strona logowania'},
  },
  {
    path: '/switch-user/:user_uuid/token/:user_token',
    name: 'recovery_switch_user',
    component: SwitchUser,
    meta: { requiresAuth: false, fullName: 'Przełączanie kontekstu użytkownika'},
  },
  {
    path: '/login/recovery',
    name: 'recovery',
    component: Container,
    meta: { requiresAuth: false, fullName: 'Odzyskiwanie hasła'},
    children: [
      {
        path: 'step-1',
        name: 'recovery_step_1',
        component: Step1,
        meta: { requiresAuth: false, fullName: 'Odzyskiwanie hasła' },
      },
      {
        path: 'step-2/:user_uuid/token/:user_token',
        name: 'recovery_step_2',
        component: Step2,
        meta: { requiresAuth: false, fullName: 'Odzyskiwanie hasła' },
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound,
    meta: { requiresAuth: false, fullName: '404'},
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch('auth/checkTokenPresence'); // does not affect router access and authorization. Used to fill store's token property from localStorage

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['auth/authenticated']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
        if(store.getters['auth/user'].roles === null) {
          store.dispatch('auth/getMe', 'fromRouter')
          .then(()=>{
            checkRoles(to, next)
          })
          return
        } else {
          checkRoles(to, next)
        }
    }
  } else {
    next() // make sure to always call next()!
  }
});

export default router


function checkRoles(to, next){
  if(!(store.getters['auth/user'].roles.includes("ROLE_AS_ACCESS") && store.getters['auth/user'].roles.includes("ROLE_AS_NOURISHED_PLAN_LIST_SHOW"))) {
    next('/login')
    store.commit('recovery/UPDATE_ERROR', {
      status: true,
      color: '#dc3545',
      error: 'Wpisałeś błędne hasło lub email lub nie masz dostępu do aplikacji',
    }, {root: true})
    return
  }
  store.getters['auth/user'].roles.includes("ROLE_AS_ACCESS") &&
  store.getters['auth/user'].roles.includes("ROLE_AS_NOURISHED_PLAN_LIST_SHOW") &&
  store.getters['auth/user'].roles.includes(to.meta.role) ? next() : next({path: '/404', query: { redirect: to.fullPath }})
}
