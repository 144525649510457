<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';
export default {
  created() {
    !this.$route.path.includes('login') ? this.getFromApiDepartments() : null;
  },
  methods: mapActions(['getFromApiDepartments'])
}
</script>
