import { axios_instance as axios } from '@/axios-config.js';
import router from '@/router/index';
import { EventBus } from '@/main';

export default {
  namespaced: true,

  state: {
    ticketHasError: false,
    ticketItem: null,
    answerReply: ''
  },

  getters: {},

  mutations: {
    SET_ERROR(state, payload) {
      state.ticketHasError = payload;
    },
    SET_TICKET_ITEM(state, payload = null) {
      state.ticketItem = payload ? payload.data : payload;
    },
    UPDATE_ANSWER_REPLY(state, payload) {
      state.answerReply = payload;
    }
  },

  actions: {
    getTicket({ commit }) {
      axios
        .get('v1/ticket/tickets/' + router.history.current.params.id)
        .then(response => {
          commit('SET_TICKET_ITEM', response)
        })
        .catch(error => {
          commit('SET_ERROR', true);
          console.error('Error:', error);
        });
    },
    sendTicket(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('v1/ticket/tickets', {
            title: payload.formTitle,
            message: payload.formMessage,
            category: payload.formCategory,
          })
          .then(() => {
            EventBus.$emit('triggerAlert', { message: 'Tworzenie zgłoszenia zakończone powodzeniem' });
            resolve();
          })
          .catch(error => {
            EventBus.$emit('triggerAlert', {
              message: `Błąd! Nie udało się wysłać zgłoszenia`,
              triggerManualError: true
            });
            reject(error);
          });
      })
    },
    onSendAnswer({ state, dispatch }) {
      if (state.answerReply !== '') {
        return new Promise((resolve, reject) => {
          axios
            .post('v1/ticket/tickets/' + router.history.current.params.id + '/answers', {
              answer: state.answerReply,
            })
            .then(() => {
              dispatch('getTicket');
              EventBus.$emit('triggerAlert', { message: 'Odpowiedź została wysłana pomyślnie', type: 'anwserReply' });
              resolve();
            })
            .catch(error => {
              EventBus.$emit('triggerAlert', {
                message: `Błąd! Nie udało się wysłać wiadomości`,
                triggerManualError: true
              });
              reject(error);
            });
        })
      }
    },
    onCloseTicket() {
      return new Promise((resolve, reject) => {
        axios
          .patch('v1/ticket/tickets/' + router.history.current.params.id + '/close')
          .then(() => {
            EventBus.$emit('triggerAlert', { message: 'Zamknięcie zgłoszenia zakończone sukcesem' });
            resolve();
          })
          .catch(error => {
            EventBus.$emit('triggerAlert', {
              message: `Błąd! Nie udało się zamknąć zgłoszenia`,
              triggerManualError: true
            });
            reject(error);
          });
      })
    }
  }
}
