import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth"
import recovery from './recovery'
import plans from './plans'
import menu from './menu'
import tickets from './tickets'
import knowledgeBase from './knowledgeBase'
import e_learning from './elearning'
import {axios_instance as axios} from '@/axios-config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    departments: null,
    selectedDepartment: null
  },

  mutations: {
    SET_DEPARTMENTS(store, data) {
      store.departments = data;
    },
    SET_SELECTED_DEPARTMENT(store, payload) {
      store.selectedDepartment = payload
    }
  },

  actions: {
    async getFromApiDepartments({ commit }) {
      const response = await axios.get('/v1/account/accounts/departments');
      commit('SET_DEPARTMENTS', response.data)
      commit('SET_SELECTED_DEPARTMENT', response.data[0])
    }
  },

  modules: {
    auth,
    recovery,
    plans,
    menu,
    tickets,
    knowledgeBase,
    e_learning
  }
})
