
import axios from 'axios';
import store from '@/store';
import router from '@/router';

export const baseUrl = process.env.VUE_APP_ROOT_API_BASEURL;

export const axios_instance = axios.create({
    baseURL: baseUrl + '/api',
});


axios_instance.interceptors.request.use(
  function(request) {
    return request;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios_instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if(error.response.data.code === 401) {
      store.commit('auth/SET_TOKEN', null);
      console.log('401 error');
      router.push({name: 'login'});
    }
    return Promise.reject(error);
  }
);
