<template>
  <div class="container-fluid" style='margin-top:30px'>
    <vue-progress-bar></vue-progress-bar>
    <template v-if="nourishedPlan.uuid && serverTime !== null">

      <div class="row">
        <div v-if="nourishedPlan.status" class="col-12">
          <div class="alert alert-warning alert--grouped mb-4">
            <i class="fe fe-alert-triangle mr-2"></i>
            Plan został już zatwierdzony, więc możliwy jest tylko jego podgląd lub korekta.
          </div>
        </div>

        <div class="col-md-12">
          <div class="card-deck">

            <div class="card card-content shadow-sm">
              <div class="card-header shadow border-primary">
                <div class="d-flex align-content-between border-bottom mb-1 pb-0">
                  <p class="h3 text-muted mb-1">Klient</p>
                  <div class="card-options ml-auto align-self-center"></div>
                </div>
                <span class="card-title">{{ nourishedPlanContract.company.name }}</span>
              </div>
              <div class="card-body">
                <p class="small border-bottom pb-1 mb-1">
                  <span class="text-muted"><i class="fe fe-tag pr-2"></i>Oddział:</span>
                  <template v-if="nourishedPlanContract">
                    {{ nourishedPlanContract.company.companyDepartment.name }}
                  </template>
                </p>
                <p class="small mb-0">
                  <span class="text-muted"><i class="fe fe-map-pin pr-2"></i>Adres:</span>
                  <template v-if="nourishedPlanContract.company.companyDepartment">
                    {{ nourishedPlanContract.company.companyDepartment.address }} {{ nourishedPlanContract.company.companyDepartment.postalCode }}
                    {{ nourishedPlanContract.company.companyDepartment.city }}
                  </template>
                  <template v-else>
                    {{ nourishedPlanContract.company.address }} {{ nourishedPlanContract.company.postalCode }}
                    {{ nourishedPlanContract.company.company.city }}
                  </template>
                </p>
              </div>
            </div><!-- /card -->

            <!-- breakpoint separator -->
            <div class="w-100 d-lg-none mt-2"></div>

            <div class="card card-content shadow-sm">
              <div class="card-header shadow border-warning">
                <div class="d-flex align-content-between border-bottom mb-1 pb-0">
                  <p class="h3 text-muted mb-1">Szczegóły planu</p>
                  <div class="card-options ml-auto align-self-center">
                    <span v-if="nourishedPlan.status" class="badge badge-success p-2"><i class="fe fe-lock mr-2"></i>Zatwierdzony</span>
                    <span v-else class="badge badge-primary p-2"><i class="fe fe-unlock mr-2"></i>Otwarty</span>
                  </div>
                </div>
                <span class="card-title">
                  <span class="text-muted"><i class="fe fe-user pr-2"></i>Sporządził:</span>
                  {{ nourishedPlan.createdBy }}<br/>
                  <span class="text-muted"><i class="fe fe-calendar pr-2"></i>Dnia:</span>
                  {{ nourishedPlan.createdAt }}
                </span>
              </div>
              <div class="card-body">
                <p class="small border-bottom pb-1 mb-1">
                  <span class="text-muted"><i class="fe fe-activity pr-2"></i>Status:</span>
                  {{ nourishedPlan.status ? 'Zatwierdzony automatycznie' : 'Otwarty' }}
                </p>
                <template v-if="canEditDescriptionTextArea && grantedEdit">
                  <textarea
                      @change="updateDocument({'notes': nourishedPlan.notes})"
                      class="form__textarea w-100"
                      name="notes"
                      :disabled='isBeingUpdated'
                      placeholder="Uwagi"
                      rows="2"
                      v-model="nourishedPlan.notes"
                  >
                  </textarea>
                </template>
                <template v-else>
                  <p class="small mb-0">
                    <span class="text-muted"><i class="fe fe-message-square pr-2"></i>Uwagi:</span>
                    {{ nourishedPlan.notes }}
                  </p>
                </template>
              </div>
            </div><!-- /card -->

            <!-- breakpoint separator -->
            <div class="w-100 d-lg-none mt-2"></div>

            <div class="card card-content card-content--extras shadow-sm">
              <div class="card-header">
                <div class="card-icon bg-primary shadow">
                  <i class="fe fe-calendar h2"></i>
                </div>
                <p class="card-category text-right">Plan na dzień</p>
                <h3 class="card-title">{{ nourishedPlan.planDate }}</h3>
              </div>
              <div class="card-body py-2 d-flex align-items-center">
                <i class="fe fe-clock text-warning"></i>
                <span class="text-warning ml-2">
                  Termin złożenia planu mija:
                  <strong>{{ nourishedPlan.dayToSendNutritionPlan }} {{ nourishedPlanContract.timeToSendNutritionPlan }}</strong>
                </span>
              </div>
            </div><!-- /card -->
          </div><!-- /card deck -->
        </div><!-- /col-12 -->
      </div><!-- /row -->

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h5 class="mt-4">Lista</h5>
            </div>
            <div class="col text-right">
              <div class="custom-control custom-switch mt-4">
                <input type="checkbox" class="custom-control-input" v-model="showDietsOnlyForDepartment" id="showDietsOnlyForDepartment">
                <label class="custom-control-label" for="showDietsOnlyForDepartment">Pokaż tylko diety dla oddziału</label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <template v-if="nourishedPlanContract === null">
              <p class="text-center font-weight-bold">Brak aktywnej umowy</p>
            </template>
            <template v-else-if="nourishedPlanContract">
              <table class="table table--grid table-striped table-bordered table-sm">
                <thead class="thead-light">
                <tr class="d-flex border-bottom">
                  <th class="col-3 align-bottom">Stan żywionych</th>
                  <th class="col border-left text-center" :key='index' v-for="(meal, index) in nourishedPlanContract.meals">
                    <div class="row mx-0 text-muted">
                      <div class="col">{{ countItemsOnColumn(meal.dMealId).meal }}</div>
                      <div class="col" v-if="!canEditPlanValues || !grantedEdit">{{ countItemsOnColumn(meal.dMealId).correction }}</div>
                    </div>
                  </th>
                </tr>
                <tr class="d-flex">
                  <th class="col-3 align-bottom">Nazwa diety</th>
                  <th class="col" :key='index' v-for="(meal, index) in nourishedPlanContract.meals">
                    {{ convertMealIdToMealName(meal.dMealId) }}<br/>
                    <span class="small">
                      Korekta do godz.: {{ meal.timeToCorrect ? meal.timeToCorrect : null }}
                    </span>
                    <div v-if="!canEditPlanValues || !grantedEdit" class="row mx-0">
                      <div class="col pl-0">Plan</div>
                      <div class="col pr-0">Korekta</div>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="nourishedPlanDiet in filteredNourishedPlanDiets">
                  <tr class="d-flex" :key='nourishedPlanDiet.nourishedPlanDietUuid'>
                    <td class="col-3">
                      <div style='height: 100%; display: flex; align-items: center;'>
                        <span v-if='nourishedPlanDiet.dietColor'
                              class="color-circle"
                              :style='{backgroundColor: nourishedPlanDiet.dietColor}'
                        ></span>
                        {{ nourishedPlanDiet.dietName }} {{ nourishedPlanDiet.dietIsSpecial ? '(Specjalna)' : null }}
                      </div>
                    </td>
<!--                    <template v-if="cDiet.activeMenu.uuid === undefined">-->
<!--                      <td class="text-center font-italic text-warning col" :colspan="nPlanData.contractDetails.contractMeals.length">-->
<!--                        Brak aktywnego menu dla tej diety.-->
<!--                      </td>-->
<!--                    </template>-->
<!--                    <template v-else>-->
                    <template>
                      <td v-for="(cMeal, index) in nourishedPlanContract.meals" :key='index' class="col">
                        <template v-for="nourishedPlanPosition in nourishedPlanDiet.nourishedPlanPositions">
                          <template v-if="nourishedPlanPosition.dMealId === cMeal.dMealId">
                            <template v-if="canEditPlanValues && grantedEdit">
                              <TableInput
                                  :key='nourishedPlanPosition.nourishedPlanDietUuid'
                                  :uuid='nourishedPlanPosition.uuid'
                                  :correction='false'
                                  :peopleNumber='nourishedPlanPosition.peopleNumber'
                                  :diet='nourishedPlanDiet'
                              >
                              </TableInput>
                            </template>
                            <template v-else>
                              <div class="row mx-0" :key='nourishedPlanPosition.nourishedPlanDietUuid'>
                                <div class="col pl-0">
                                  <div class="w-100 no-input">
                                    {{ nourishedPlanPosition.peopleNumber ? nourishedPlanPosition.peopleNumber : "-" }}&nbsp;
                                  </div>
                                </div>
                                <div class="col pr-0">
                                  <template v-if="hasCorrectionTimePassed(cMeal) && grantedEdit">
                                    <TableInput
                                        :correction='true'
                                        :uuid='nourishedPlanPosition.uuid'
                                        :correctionValue='nourishedPlanPosition.correction'
                                        :diet='nourishedPlanDiet'>
                                    </TableInput>
                                  </template>
                                  <template v-else>
                                    <div class="w-100 no-input">
                                      {{ nourishedPlanPosition.correction ? nourishedPlanPosition.correction : '-' }}&nbsp;&nbsp;
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </template>
                          </template>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                </tbody>
              </table>
            </template>
            <template v-else>
              <div class="loader"></div>
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <p class="small mb-0">
            <span class="text-muted">Data utworzenia:</span>{{ nourishedPlan.createdAt }}
          </p>
          <p class="small">
            <span class="text-muted">Data modyfikacji:</span>{{ nourishedPlan.updatedAt }}
          </p>
        </div>
        <div class="col-md-6 text-right">
          <button v-if="!nourishedPlan.status && grantedEdit" @click="updateDocument" class="basic-info__add-btn">Zakończ</button>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="loader"></div>
    </template>

    <div v-if="isBeingUpdated" class='loader-container'>
      <div class="loader loader--fixed"></div>
    </div>
  </div><!-- /container-fluid -->
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css';
import { EventBus } from '@/main';
import TableInput from '../TableInput.vue';
import { mapActions, mapState } from 'vuex';

export default {
  props: ['createForFewDays'],
  components: {
    TableInput,
  },
  data: function() {
    return {
      grantedEdit: true,
      showDietsOnlyForDepartment: true,
    };
  },
  created: function() {
    this.getNourishedPlanData(this.$route.params.id);

    EventBus.$on('newPositionValue', ({ response, diet, uuid }) => {
      this.computedPositions[diet.uuid].nourishedPlanPositions[uuid] = response.data;
    });
  },
  computed: {
    ...mapState('plans', [ 'nourishedPlan', 'nourishedPlanContract', 'nourishedPlanDietsReadOnly', 'serverTime', 'isBeingUpdated']),
    canEditPlanValues: function() {
      if (this.nourishedPlan !== null && this.serverTime !== null && this.nourishedPlanContract !== null) {
        const maxPlanDate = new Date(this.nourishedPlan.dayToSendNutritionPlan+'T'+this.nourishedPlanContract.timeToSendNutritionPlan);
        return this.nourishedPlan.status === true ? false : maxPlanDate > this.serverTime;
      } else {
        return null;
      }
    },
    canEditDescriptionTextArea() {
      let meals = this.nourishedPlanContract.meals;
      let maxCorrectionDate = null;
      for (let i = 0; i < meals.length; i++) {
        let correctDate = new Date(this.nourishedPlan.planDate+'T'+meals[i].timeToCorrect);
        maxCorrectionDate = correctDate > maxCorrectionDate ? correctDate : maxCorrectionDate;
      }
      return maxCorrectionDate > this.serverTime;
    },
    filteredNourishedPlanDiets() {
      if (this.showDietsOnlyForDepartment && this.nourishedPlanContract.diets) {
        return this.nourishedPlanDietsReadOnly.filter(pd => this.nourishedPlanContract.diets.some(dd => pd.dietUuid === dd.uuid));
      }
      return this.nourishedPlanDietsReadOnly;
    },
  },
  methods: {
    ...mapActions('plans', ['getNourishedPlanData']),
    updateDocument() {
      this.$Progress.start()
      this.$store.dispatch('plans/updateDocument')
          .then(() => this.$Progress.finish())
          .catch(error => {
            this.$Progress.fail()
            console.error('Error:', error);
          })
    },
    countItemsOnColumn(dMealId) {
      const totals = {
        1: {meal: 0, correction: 0},
        2: {meal: 0, correction: 0},
        3: {meal: 0, correction: 0},
        4: {meal: 0, correction: 0},
        5: {meal: 0, correction: 0}
      };

      Object.values(this.nourishedPlanDietsReadOnly).forEach(diet => {
        Object.values(diet.nourishedPlanPositions).forEach(position => {
          if (totals[position.dMealId] !== undefined) {
            totals[position.dMealId].meal += position.peopleNumber;
            totals[position.dMealId].correction += position.correction;
          }
        });
      });

      return totals[dMealId];
    },
    convertMealIdToMealName(mealId) {
      const mealNames = {
        1: "Śniadanie",
        2: "Drugie śniadanie",
        3: "Obiad",
        4: "Podwieczorek",
        5: "Kolacja"
      };
      return mealNames[mealId] || "Nieznany posiłek";
    },
    hasCorrectionTimePassed(cMeal) {
      return new Date(this.nourishedPlan.dayToCorrectNutritionPlan+'T'+cMeal.timeToCorrect) > this.serverTime;
    },
  },
  watch: {
    '$route'() {
      this.getNourishedPlanData(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/variables';
@import '../../assets/css/scoped-forms';

.no-input {
  background-color: #ebebeb;
  border-radius: 5px;
  border: 1px solid $form-input-border-col;
  padding: 3px 0 3px 10px;
}

input, textarea {
  background-color: white !important;
  // border-style: none;
  // color: inherit;
}

.form__select {
  appearance: initial;
  background-color: white !important;
  -webkit-appearance: menulist;
}

.color-circle {
  min-width:10px;
  margin-right:5px;
  display:inline-block;
  width:10px;
  height:10px;
  border-radius:50%
}
</style>
