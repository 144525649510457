<template>
  <div class="content">
   Proszę czekać... Przełączam kontekst użytkownika...
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'recovery_switch_user',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
    }
  },
  created: function() {
    this.switchUser();
  },
  computed: {
    ...mapState('recovery', ['error']),
  },
  methods: {
    ...mapActions({
      switchUser: 'auth/switchUser',
    }),

  },
}
</script>

