import {axios_instance as axios} from '@/axios-config.js';
import router from '@/router/index';

export default {
  namespaced: true,

  state: {
    knowledgeArticleList: null,
    totalArticleItems: null,
    article: null,
    knowledgeCategoryList: null,
    totalCategoryItems: null,
    knowledgeBaseHasError: false,
  },

  mutations: {
    SET_ARTICLE_LIST(state, payload) {
        state.knowledgeArticleList = payload.data
    },
    SET_TOTAL_ARTICLE_ITEMS(state, payload) {
        state.totalArticleItems = Object.keys(payload.data).length
    },
    SET_ARTICLE(state, payload) {
        state.article = payload.data
    },
    SET_CATEGORY_LIST(state, payload) {
      state.knowledgeCategoryList = payload.data
    },
    SET_TOTAL_CATEGORY_ITEMS(state, payload) {
      state.totalCategoryItems = Object.keys(payload.data).length
    },
    SET_ERROR(state, payload) {
      state.knowledgeBaseHasError = payload;
    },
  },

  actions: {
    setKnowledgeArticlesList({ commit }) {
        return new Promise((resolve, reject)=>{
            axios
            .get('v1/knowledge-base/articles')
            .then(response => {
                commit('SET_ARTICLE_LIST', response)
                commit('SET_TOTAL_ARTICLE_ITEMS', response)
                resolve();
            })
            .catch(error => {
                reject(error)
            });
        })
    },
    getKnowledgeBaseContents({ commit }) {
        return new Promise((resolve, reject)=>{
            axios
            .get('v1/knowledge-base/articles/' + router.history.current.params.knowledgeArticleId)
            .then(response => {
                commit('SET_ARTICLE', response)
                resolve()
            })
            .catch(error => {
                reject(error)
            });
        })
    },
    getKnowledgeBaseCategories({ commit }) {
      return new Promise((resolve, reject)=>{
        axios
          .get('v1/knowledge-base/categories')
          .then(response => {
            commit('SET_CATEGORY_LIST', response);
            commit('SET_TOTAL_CATEGORY_ITEMS', response);
            resolve();
          })
          .catch(error => {
            commit('SET_ERROR', true);
            reject(error);
          });
      })
    },
  }
}
