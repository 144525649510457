<script>
export default {
  name: 'MiniCalendarDayView',
  props: {
    date: {
      type: Date,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    day() {
      return this.date.getDate();
    },
    monthLongName() {
      let monthLongName = this.date.toLocaleString('default', { month: 'long' });
      return monthLongName.charAt(0).toUpperCase() + monthLongName.slice(1);
    },
    yearFull() {
      return this.date.getFullYear();
    },
    shouldShowIcon() {
      return this.showIcon;
    }
  }
}
</script>

<template>
  <div class="month-number-and-name">
    <span>
      <span class="day">{{ day }}</span>
      <i v-if="shouldShowIcon" class='fe fe-check'/>
      <small>{{ monthLongName }} {{ yearFull }}</small>
    </span>
  </div>
</template>

<style scoped lang="scss">
.month-number-and-name {
  span p {
    margin: 0;
    padding: 0;
  }
  span small {
    display: block;
    margin: 0;
    padding: 0;
  }
}
.day {
  font-size: larger;
}
</style>
