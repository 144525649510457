<template>
  <form class="right-block__form  form--columned form">
        <input
            v-model='email'
            type="email"
            id="recovery_step1_email"
            required="required"
            placeholder="Adres e-mail"
            maxlength="255"
            class="form__input form__input"
        />

        <label
            v-if='error.status'
            class="form__error"
            :style='{color: error.color}'
            v-html='error.message'>
        </label>
        <div>
            <button @click.prevent='sendEmail' id="recovery_step1_Odzyskaj hasło" class="form__button">Odzyskaj hasło</button>
        </div>
    </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'Step1Page',
    computed: {
        ...mapState('recovery', ['error']),
        email: {
            get () {
                return this.$store.state.email
            },
            set (value) {
                this.$store.commit('recovery/UPDATE_EMAIL', value)
            }
        }
    },
    methods: mapActions('recovery', ['sendEmail'])
}
</script>
<style lang="scss">

</style>
