<template>
  <div class="col-12 col-xl-10 content" style="padding-top: 0;">
    <div class="content__columns shadow-lg mt-n2">
      <router-view />
    </div>
    <custom-alert />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/main'
import Alert from './AlertView.vue'
export default {
  name: 'ContentView',
  components: {
    'custom-alert': Alert,
  },
  data() {
    return {
      dietShortcut: null,
      dietColor: null,
    }
  },
  created() {
    const routes = this.$router.history.current.fullPath.split('/')
    for (const [value, index] of routes.entries()) {
      if (value === '') {
        routes.splice(index, 1)
      }
    }
  },
  computed: mapState('menu', ['componentData']),
  methods: {
    checkPath(value) {
      const firstPathPart = value.split('/')
      if (firstPathPart.includes('menus')) {
        return true
      }
    },
    getContrastYIQ(hexcolor) {
      var r = parseInt(hexcolor.substr(1, 3), 16)
      var g = parseInt(hexcolor.substr(3, 4), 16)
      var b = parseInt(hexcolor.substr(5, 3), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 128 ? 'black' : 'white'
    },
  },
  watch: {
    $route() {
      const routes = this.$router.history.current.fullPath.split('/')
      for (const [value, index] of routes.entries()) {
        if (value === '') {
          routes.splice(index, 1)
        }
      }
      this.dietShortcut = null
      this.dietColor = null
    },
    componentData() {
      if (this.componentData !== null) {
        this.dietShortcut = this.componentData.diet.shortcut
        this.dietColor = this.componentData.diet.color
        EventBus.$emit('dietChildData', this.componentData.diet)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../assets/css/variables';
.content__columns {
  width: 100%;
}
</style>
