import {axios_instance as axios} from '@/axios-config.js';
import router from '@/router/index';
export default {
  namespaced: true,

  state: {
    componentData: null,
    loading: false
  },

  getters: {

  },

  mutations: {
    SET_COMPONENT_DATA(state, payload) {
        if(payload === null) {
          state.componentData = null;
        } else {
          state.componentData = payload;
          // state.componentData = {
          // ...payload.data,
          // menuDays: payload.data.menuDays.map(day => {
          //     return {
          //     ...day,
          //     menuMeals: day.menuMeals.map(meal => {
          //         return {
          //         ...meal,
          //         editMode: false,
          //         };
          //     }),
          //     };
          // }),
          // };
        }
    },
    UPDATE_LOADER(state, payload) {
        state.loading = payload
    }
  },

  actions: {
    getMenus({ commit }) {
        commit('UPDATE_LOADER', true);
        // axios.get(`app/menus/${router.history.current.params.id}?context=menu`)
        axios.get(`v1/diet/menu/${router.history.current.params.id}`)
        .then(response => {
            commit('SET_COMPONENT_DATA', response.data);
            commit('UPDATE_LOADER', false);
            this.loading = false;
        }).catch(() => {
            commit('UPDATE_LOADER', false);
        });
    }
  }
}
